var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"change-password-modal","title":"Sprememba gesla"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return cancel()}}},[_c('span',{staticClass:"align-middle"},[_vm._v(" Prekliči ")])]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.changePassword}},[_vm._v(" Spremeni geslo ")])]}}])},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":"Staro geslo"}},[_c('div',{staticClass:"w-full mb-2"},[_c('validation-provider',{attrs:{"name":"Staro geslo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autofocus":"","name":"old_password","type":"password"},model:{value:(_vm.password_change.old_password),callback:function ($$v) {_vm.$set(_vm.password_change, "old_password", $$v)},expression:"password_change.old_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-form-group',{attrs:{"label":"Novo geslo"}},[_c('div',{staticClass:"w-full mb-2"},[_c('validation-provider',{attrs:{"name":"Novo geslo","rules":"required","vid":"new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"new_password","type":"password"},model:{value:(_vm.password_change.new_password),callback:function ($$v) {_vm.$set(_vm.password_change, "new_password", $$v)},expression:"password_change.new_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-form-group',{attrs:{"label":"Ponovi novo geslo"}},[_c('div',{staticClass:"w-full mb-2"},[_c('validation-provider',{attrs:{"name":"Ponovi novo geslo","rules":"required|confirmed:new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"repeat_new_password","type":"password","data-vv-as":"new_password"},model:{value:(_vm.password_change.repeat_new_password),callback:function ($$v) {_vm.$set(_vm.password_change, "repeat_new_password", $$v)},expression:"password_change.repeat_new_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }