import Vue from 'vue'

// axios
import axios from 'axios'
import store from '@/store'
import router from '@/router'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axios.interceptors.response.use(function(response) {
    return response
}, function(error) {
    if (error.response.status === 401) {
        store.dispatch('user/setLoginStatus', false)
        router.push('/login').then()
    }
    return Promise.reject(error)
})


Vue.prototype.$http = axiosIns

export default axiosIns
